














import { Component, Vue } from "vue-property-decorator";
import FeatureCard from "@/components/FeatureCard.vue";

@Component({
  components: {
    FeatureCard,
  },
})
export default class ScrumForTeams extends Vue {}
