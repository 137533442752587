








import { Component, Prop, Vue } from "vue-property-decorator";
import feature from "@/types/Feature";

@Component
export default class FeatureCard extends Vue {
  @Prop({
    required: true,
  })
  private feature!: feature;

  get avatar(): string {
    return require(this.feature.avatar ?? "");
  }
}
