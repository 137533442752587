



















































































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class CV extends Vue {
  cardText =
    "Erfahrener Scrum Master und Geschäftsführer der Point " +
    "Pioneers GmbH mit umfassender Expertise in Webentwicklung, sowie der " +
    "erfolgreichen Implementierung von Scrum und anderen agilen Methoden mit " +
    "Leidenschaft für effektive Produktentwicklung.";

  skills = [
    "Scrum",
    "Objectives & Key Results",
    "Kanban",
    "Scrum Master",
    "Product Owner",
    "Webentwicklung",
    "JIRA",
    "DevOps",
    "GitLab",
    "Docker",
    "Coaching",
    "Stakeholder-Management",
    "Lean Management",
    "Evidence Based Management",
    "Unternehmensführung",
  ];
}
