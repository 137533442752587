


















import { Component, Vue } from "vue-property-decorator";
import Hero from "@/Views/Hero.vue";
import ScrumForTeams from "@/Views/ScrumForTeams.vue";

@Component({
  components: {
    Hero,
    ScrumForTeams,
  },
})
export default class Scrum extends Vue {}
